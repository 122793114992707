import { useEffect } from "react";

import "react-vertical-timeline-component/style.min.css";

import {
  Center,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import "./Schedule.css";

import NavBar from "./NavBar";
import Footer from "./Footer";

export default function Schedule() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="pt-2 pb-2 px-3 text-center d-flex row justify-content-center">
        <Text
          py={4}
          fontSize={{ base: "4xl", md: "6xl" }}
          className="mb-1 schedule_title"
        >
          Wedding Schedule
        </Text>
        <Text
          w={{ base: "100%", md: "75%" }}
          // letterSpacing={{ base: "0", md: "0.1em" }}
          lineHeight={{ base: "6", md: "7" }}
          className="schedule__section__text lh-lg"
          style={{ textJustify: "inter-word", textAlign: "center" }}
        >
          Below is an outline of our exciting wedding weekend!
        </Text>
      </div>
      <div className="py-2">
        <hr className="hr hr-blurry" />
      </div>
      <div className="pt-2 px-3 text-center d-flex row justify-content-center w-full">
        <Center width={"100%"}>
          <div className="w-11/12 h-full mb-0 pb-0 mt-4">
            <Text
              fontSize={{ base: "3xl", md: "5xl" }}
              className="schedule_sub_title"
            >
              Welcome Picnic
            </Text>
            <Center width={"100%"}>
              <Image
                width={{ base: "100%", md: "50%" }}
                borderRadius={"3xl"}
                boxShadow={"md"}
                padding={1}
                marginBottom={4}
                marginTop={2}
                backgroundColor={"#fffaff"}
                src={require("./assets/schedule/estses-park.webp")}
              ></Image>
            </Center>
            <Center>
              <VStack width={{ base: "100%", md: "50%" }}>
                <Text
                  w={{ base: "100%", md: "100%" }}
                  // letterSpacing={{ base: "0", md: "0.1em" }}
                  lineHeight={{ base: "6", md: "7" }}
                  className="schedule__section__text lh-lg mb-4"
                  style={{ textJustify: "inter-word", textAlign: "center" }}
                >
                  If you find yourself in town a little bit early, join us for
                  lawn games and burgers at the local park. Attendance is purely
                  optional, but provides a great chance to say hello before the
                  main event!
                </Text>
                <Text
                  w={{ base: "100%", md: "100%" }}
                  // letterSpacing={{ base: "0", md: "0.1em" }}
                  lineHeight={{ base: "6", md: "7" }}
                  className="schedule__section__text lh-lg"
                  style={{ textJustify: "inter-word", textAlign: "center" }}
                >
                  <p className="font-semibold">Friday, June 21</p>
                  <p>11am to 2pm Mountain Standard Time</p>
                  <p>Stanley Park in Estes Park, CO</p>
                </Text>
              </VStack>
            </Center>
            <div className="pb-5 pt-5">
              <hr className="hr hr-blurry" />
            </div>
            <Text
              fontSize={{ base: "3xl", md: "5xl" }}
              className="schedule_sub_title "
            >
              Our Wedding
            </Text>
            <Center width={"100%"}>
              <Image
                width={{ base: "100%", md: "50%" }}
                borderRadius={"3xl"}
                boxShadow={"md"}
                padding={1}
                marginBottom={4}
                marginTop={2}
                backgroundColor={"#fffaff"}
                src={require("./assets/schedule/black-canyon.jpg")}
              ></Image>
            </Center>
            <Center>
              <VStack width={{ base: "100%", md: "50%" }}>
                <Text
                  w={{ base: "100%", md: "100%" }}
                  // letterSpacing={{ base: "0", md: "0.1em" }}
                  lineHeight={{ base: "6", md: "7" }}
                  className="schedule__section__text lh-lg mb-4"
                  style={{ textJustify: "inter-word", textAlign: "center" }}
                >
                  The event we've all been waiting for! Join us to celebrate our
                  marriage.
                </Text>
                <Text
                  w={{ base: "100%", md: "100%" }}
                  // letterSpacing={{ base: "0", md: "0.1em" }}
                  lineHeight={{ base: "6", md: "7" }}
                  className="schedule__section__text lh-lg"
                  style={{ textJustify: "inter-word", textAlign: "center" }}
                >
                  <p className="font-semibold">Saturday, June 22</p>
                  <p>Ceremony begins at 4pm Mountain Standard Time</p>
                  <p>The Boulders at Black Canyon Inn</p>
                  <p>Estes Park, CO</p>
                </Text>
              </VStack>
            </Center>
            <div className="pb-5 pt-5">
              <hr className="hr hr-blurry" />
            </div>
            <Text
              fontSize={{ base: "3xl", md: "5xl" }}
              className="schedule_sub_title"
            >
              Grab and Go Brunch
            </Text>
            <Center width={"100%"}>
              <Image
                width={{ base: "100%", md: "50%" }}
                borderRadius={"3xl"}
                boxShadow={"md"}
                padding={1}
                marginBottom={4}
                marginTop={2}
                backgroundColor={"#fffaff"}
                src={require("./assets/schedule/coffee-cheers.webp")}
              ></Image>
            </Center>
            <Center className="mb-8">
              <VStack width={{ base: "100%", md: "50%" }}>
                <Text
                  w={{ base: "100%", md: "100%" }}
                  // letterSpacing={{ base: "0", md: "0.1em" }}
                  lineHeight={{ base: "6", md: "7" }}
                  className="schedule__section__text lh-lg mb-4"
                  style={{ textJustify: "inter-word", textAlign: "center" }}
                >
                  Before you head out of town we welcome you to swing by to say
                  goodbye. We will have coffee and breakfast items to go!
                </Text>
                <Text
                  w={{ base: "100%", md: "100%" }}
                  // letterSpacing={{ base: "0", md: "0.1em" }}
                  lineHeight={{ base: "6", md: "7" }}
                  className="schedule__section__text lh-lg"
                  style={{ textJustify: "inter-word", textAlign: "center" }}
                >
                  <p className="font-semibold">Sunday, June 23</p>
                  <p>10am to 1pm Mountain Standard Time</p>
                  <p>Location details to come</p>
                </Text>
              </VStack>
            </Center>

            {/* <VerticalTimeline lineColor="black">
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#3c3d33",
                  color: "#fff",
                  borderRadius: "12px",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #3c3d33",
                }}
                date="Friday, June 21"
                dateClassName="date_text_a"
                iconStyle={{ background: "#3c3d33", color: "#fff"}}
                icon={<GiBarbecue />}
              >
                <h3
                  className="text-3xl font-bold"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Welcome Picnic
                </h3>
                <h4
                  className="text-xl"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Estes Park, Colorado
                </h4>
                <p
                  className="text-justify"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  As you arrive and enjoy the scenery at Estes Park please stop
                  by our welcome Picnic to say hello and meet other friends and
                  family who will attend the wedding!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#3c3d33",
                  color: "#fff",
                  borderRadius: "12px",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #3c3d33",
                }}
                date="Saturday, June 22"
                dateClassName="date_text_a"
                iconStyle={{ background: "#3c3d33", color: "#fff" }}
                icon={<FaGlassCheers />}
              >
                <h3
                  className="text-3xl font-bold"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Welcome Ceremony
                </h3>
                <h4
                  className="text-xl"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Boulders at Black Canyon Inn
                </h4>
                <p
                  className="text-center"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Our wedding ceremony and reception to follow!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#3c3d33",
                  color: "#fff",
                  borderRadius: "12px",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #3c3d33",
                }}
                date="Sunday, June 22"
                dateClassName="date_text_a"
                iconStyle={{ background: "#3c3d33", color: "#fff" }}
                icon={<MdBrunchDining />}
              >
                <h3
                  className="text-3xl font-bold"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Post Wedding Brunch
                </h3>
                <h4
                  className="text-xl"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Estes Park, Colorado
                </h4>
                <p
                  className="text-justify"
                  style={{ fontFamily: "Quattrocento, serif" }}
                >
                  Please join us on your way out of town or to get a little more
                  quality time with family and friends the day after we tie the
                  knot!
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline> */}
            {/* <Chrono
              items={items}
              toolbarPosition={"BOTTOM"}
              mode="VERTICAL_ALTERNATING"
              slideshow
              slideItemDuration={4000}
              scrollable={{ scrollbar: false }}
              timelinePointShape={"diamond"}
              theme={{
                primary: "black",
                secondary: "white",
                cardBgColor: "white",
                titleColor: "black",
                titleColorActive: "black",
              }}
              fontSizes={{
                cardSubtitle: "0.85rem",
                cardText: "0.8rem",
                cardTitle: "1rem",
                title: "1rem",
              }}
              buttonTexts={{
                first: "Jump to First",
                last: "Jump to Last",
                next: "Next",
                previous: "Previous",
              }}
            ></Chrono> */}
          </div>
        </Center>
      </div>
      <Footer />
    </>
  );
}
