import "./ThingsToDo.css";

import React from "react";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import NavBar from "./NavBar";
import Footer from "./Footer";

export default function ThingsToDo() {
  return (
    <div className="thingstodo_background_gradient" style={{}}>
      <NavBar />
      <div className="container">
        <p className="text-center pt-4 pb-0 section__title">
          Venue and Reception Address
        </p>
        <hr className="hr hr-blurry" />
        <div className="pt-3 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://blackcanyoninn.com/">
              <img
                className="card-img-top"
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/boulders.jpg")}
                alt="Boulders at Black Canyon Inn"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">
                800 MacGregor Ave, Estes Park, CO 80517
              </h5>
              <h6 className="text-center section__text text-wrap">
                Note: You may begin arriving at the venue at 3:45-4:00pm on
                6/22/2024.
              </h6>
              <Itinerary />
            </div>
          </div>
        </div>

        <p className="text-center pt-5 pb-0 section__title">Lodging on-site</p>
        <hr className="hr hr-blurry" />
        <div className="pt-3 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://blackcanyoninn.com/">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/babcinn.jpg")}
                alt="Boulders at Black Canyon Inn"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">
                Boulders at Black Canyon Inn
              </h5>
              <h6 className="text-center section__text">
                Suite rentals are available with 1, 2, and 3 bedrooms. Suites
                can be booked out as soon as now.
              </h6>
            </div>
          </div>
        </div>

        <p className="text-center pt-5 pb-0 section__title">Lodging off-site</p>
        <hr className="hr hr-blurry" />
        <div className="pt-3 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://www.stanleyhotel.com/accommodations.html">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/stanley.jpg")}
                alt="The Stanley Hotel"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">The Stanley Hotel</h5>
              <h6 className="text-center section__text">
                There is a two night minimum at the historic Stanley where
                Stephen King became inspired to write the Shining.
              </h6>
            </div>
          </div>
        </div>

        <div className="pt-5 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://www.fallrivervillage.com/?utm_source=google&utm_campaign=local&utm_medium=organic">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/fallriver.jpg")}
                alt="Fall River Village Resort"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">
                Fall River Village Resort
              </h5>
              <h6 className="text-center section__text">
                Offers accomodations within throwing distance from downtown
                Estes Park and Rocky Mountain National Park. It doesn't take
                much luck to open your door to a pack of Mule Deer or Elk
                passing through the property.
              </h6>
            </div>
          </div>
        </div>

        <div className="pt-5 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <img
              style={{ width: "100%", objectFit: "cover" }}
              src={require("./assets/todo/airbnbvrbo.jpg")}
              alt="AriBnB/VRBO"
            />
            <div className="card-body">
              <div className="text-center section__text">
                <a href="https://www.airbnb.com/">AirBnB</a> &{" "}
                <a href="https://www.vrbo.com/">VRBO</a>
              </div>
              <div className="text-center section__text">
                If renting through Airbnb/VRBO you'll want to make sure the unit
                is actually in Estes Park. Sometimes the search engine thinks
                that Rocky Mountain National Park (a few minutes drive away) is
                where you want to be and will show you units near Grand Lake.
              </div>
            </div>
          </div>
        </div>

        <h1 className="text-center pt-5 pb-0 section__title">
          Restaurant Recommendations
        </h1>
        <hr className="hr hr-blurry" />

        <p className="text-center pt-3 pb-0 section__subtitle">Breakfast</p>
        <div className="pt-3 pb-5 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://www.thenotchtop.com/">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/notchtop.jpg")}
                alt="Notchtop"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">Notchtop</h5>
            </div>
          </div>
        </div>

        <p className="text-center pt-3 pb-0 section__subtitle">Lunch</p>
        <div className="pb-0 pb-5 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://bossburgersandgyros.com/">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/boss.jpg")}
                alt="Boss Burgers and Gyros"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">
                Boss Burgers and Gyros
              </h5>
            </div>
          </div>
        </div>

        <p className="text-center pt-3 pb-0 section__subtitle">Dinner</p>
        <div className="pb-3 d-flex justify-content-center">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
          >
            <a href="https://www.birdandjim.com/">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/bird.jpg")}
                alt="Bird & Jim"
              />
            </a>
            <div className="card-body">
              <h5 className="text-center section__text">Bird & Jim</h5>
              <h6 className="text-center section__text">
                We highly recommend that you make reservations if you have a
                party of four or more. It's sometimes difficult for Bird & Jim
                to seat large groups due to the popularity of the restaurant.
              </h6>
            </div>
          </div>
        </div>

        <p className="text-center pt-5 pb-0 section__title">
          Activity Recommendations
        </p>
        <hr className="hr hr-blurry" />
        <div className="pb-3 d-flex justify-content-center mb-9">
          <div
            className="card"
            style={{ backgroundColor: "#fffaf3", maxWidth: "1024px" }}
          >
            <a href="https://www.nps.gov/romo/planyourvisit/fees.htm">
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/rmnp.jpg")}
                alt="Rocky Mountain National Park"
              />
            </a>
            <div className="card-body">
              <h4 className="text-center section__text">
                Rocky Mountain National Park
              </h4>
              <h6
                variant="body2"
                color="text.secondary"
                className="pb-3 text-center section__text"
              >
                Visiting the park is an absolute must while staying in Estes.
                Arguably there is no better place in Colorado to be closer to
                the great outdoors. Regardless of where you are in RMNP you're
                bound to come face to face with wildlife at one point or
                another. Use common sense and don't get too close to the moose
                or elk! Make sure to bring plenty of water, dehydration hits
                substantially quicker at higher altitudes. Also, bring rain
                jacket, preferably one that is windproof. While it does not rain
                much at any given time, afternoon storms typically occur in the
                early afternoon and may bring some wind and light rain.
              </h6>

              <hr className="hr hr-blurry m-0" />

              <Hikes />

              <hr className="hr hr-blurry" />

              <Climbing />

              <h6
                className="text-center section__text"
                variant="body2"
                color="text.secondary"
                style={{ fontWeight: "bold", m: 1 }}
              >
                * All the activities marked by an asterisk require that you
                purchase a day pass to RMNP.
              </h6>
            </div>
          </div>
        </div>
      </div>

      <hr className="hr hr-blurry mt-12" />

      <Footer />
    </div>
  );
}

export function Hikes() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  if (width === 0 || height === 0) {
    return <HikesVertical />;
  }
  const ar = width / height;
  if (ar >= 1) {
    return <HikesHorizontal />;
  } else if (ar < 1) {
    return <HikesVertical />;
  } else {
    return <HikesVertical />;
  }
}

function HikesHorizontal() {
  return (
    <MDBRow>
      <MDBCol className="align-items-center">
        <MDBRow className="mt-0">
          <h5 className="text-center section__text">Easy Hikes</h5>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBListGroup>
              <MDBListGroupItem
                noBorders
                style={{ backgroundColor: "transparent" }}
                className="d-flex justify-content-center align-items-start section__text"
              >
                <div className="ms-2 section__text">
                  <div className="fw-bold section__text">
                    <a href="https://www.alltrails.com/trail/us/colorado/bear-lake-south">
                      Bear Lake*
                    </a>
                  </div>
                </div>
              </MDBListGroupItem>
              <MDBListGroupItem
                noBorders
                style={{ backgroundColor: "transparent" }}
                className="d-flex justify-content-center align-items-start"
              >
                <div className="ms-2 section__text">
                  <div className="fw-bold section__text">
                    <a href="https://www.alltrails.com/trail/us/colorado/lily-lake">
                      Lily Lake
                    </a>
                  </div>
                </div>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <h5 className="text-center section__text">Medium Hikes</h5>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBListGroup>
              <MDBListGroupItem
                noBorders
                style={{ backgroundColor: "transparent" }}
                className="d-flex justify-content-center align-items-start section__text"
              >
                <div className="ms-2 section__text">
                  <div className="fw-bold section__text">
                    <a href="https://www.alltrails.com/trail/us/colorado/emerald-lake-trail">
                      Emerald Lake*
                    </a>
                  </div>
                </div>
              </MDBListGroupItem>
              <MDBListGroupItem
                noBorders
                style={{ backgroundColor: "transparent" }}
                className="d-flex justify-content-center align-items-start"
              >
                <div className="ms-2 section__text">
                  <div className="fw-bold section__text">
                    <a href="https://www.alltrails.com/trail/us/colorado/ute-trail-tombstone-ridge">
                      Ute Trail*
                    </a>
                  </div>
                </div>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <h5 className="text-center section__text">Harder Hikes</h5>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBListGroup>
              <MDBListGroupItem
                noBorders
                style={{ backgroundColor: "transparent" }}
                className="d-flex justify-content-center align-items-start section__text"
              >
                <div className="ms-2 section__text">
                  <div className="fw-bold section__text">
                    <a href="https://www.alltrails.com/trail/us/colorado/deer-mountain-trail">
                      Deer Mountain*
                    </a>
                  </div>
                </div>
              </MDBListGroupItem>
              <MDBListGroupItem
                noBorders
                style={{ backgroundColor: "transparent" }}
                className="d-flex justify-content-center align-items-start"
              >
                <div className="ms-2 section__text">
                  <div className="fw-bold section__text">
                    <a href="https://www.alltrails.com/trail/us/colorado/odessa-lake-via-fern-lake-trail--3">
                      Odessa Lake*
                    </a>
                  </div>
                </div>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <h5 className="text-center section__text">Scenic Drive</h5>
        </MDBRow>
        <MDBRow>
          <h6
            variant="body2"
            color="text.secondary"
            className="text-center section__text"
          >
            Rocky Mountain National Park's Trail Ridge Road takes you all the
            way through the park to the high alpine visitor center. It is a
            fantastic way to experience the natural beauty of the park, and to
            see plenty of wildlife as well.
          </h6>
        </MDBRow>
      </MDBCol>
      <MDBCol className="d-flex align-items-center">
        <figure>
          <img
            src={require("./assets/todo/emerald_lake_2.jpeg")}
            className="img-fluid shadow-4-strong rounded"
            alt="Wild Landscape"
          />
          <figcaption className="pt-3 figure-caption section__text text-center">
            This is us at Emerald Lake during the winter. There will be
            substantially less snow around the time of our wedding.
          </figcaption>
        </figure>
      </MDBCol>
    </MDBRow>
  );
}

function HikesVertical() {
  return (
    <>
      <MDBRow>
        <h5 className="m-0 text-center section__text">Easy Hikes</h5>
      </MDBRow>
      <MDBRow>
        <MDBListGroup>
          <MDBListGroupItem
            noBorders
            style={{ backgroundColor: "transparent" }}
            className="d-flex justify-content-center align-items-start section__text"
          >
            <div className="ms-2 section__text">
              <div className="fw-bold section__text">
                <a href="https://www.alltrails.com/trail/us/colorado/bear-lake-south">
                  Bear Lake*
                </a>
              </div>
            </div>
          </MDBListGroupItem>
          <MDBListGroupItem
            noBorders
            style={{ backgroundColor: "transparent" }}
            className="d-flex justify-content-center align-items-start"
          >
            <div className="ms-2 section__text">
              <div className="fw-bold section__text">
                <a href="https://www.alltrails.com/trail/us/colorado/lily-lake">
                  Lily Lake
                </a>
              </div>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </MDBRow>
      <MDBRow>
        <h5 className="pt-4 text-center section__text">Medium Hikes</h5>
      </MDBRow>
      <MDBRow>
        <MDBListGroup>
          <MDBListGroupItem
            noBorders
            style={{ backgroundColor: "transparent" }}
            className="d-flex justify-content-center align-items-start section__text"
          >
            <div className="ms-2 section__text">
              <div className="fw-bold section__text">
                <a href="https://www.alltrails.com/trail/us/colorado/emerald-lake-trail">
                  Emerald Lake*
                </a>
              </div>
            </div>
          </MDBListGroupItem>
          <MDBListGroupItem
            noBorders
            style={{ backgroundColor: "transparent" }}
            className="d-flex justify-content-center align-items-start"
          >
            <div className="ms-2 section__text">
              <div className="fw-bold section__text">
                <a href="https://www.alltrails.com/trail/us/colorado/ute-trail-tombstone-ridge">
                  Ute Trail*
                </a>
              </div>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </MDBRow>
      <MDBRow>
        <h5 className="pt-4 text-center section__text">Harder Hikes</h5>
      </MDBRow>
      <MDBRow>
        <MDBListGroup>
          <MDBListGroupItem
            noBorders
            style={{ backgroundColor: "transparent" }}
            className="d-flex justify-content-center align-items-start section__text"
          >
            <div className="ms-2 section__text">
              <div className="fw-bold section__text">
                <a href="https://www.alltrails.com/trail/us/colorado/deer-mountain-trail">
                  Deer Mountain*
                </a>
              </div>
            </div>
          </MDBListGroupItem>
          <MDBListGroupItem
            noBorders
            style={{ backgroundColor: "transparent" }}
            className="d-flex justify-content-center align-items-start"
          >
            <div className="ms-2 section__text">
              <div className="fw-bold section__text">
                <a href="https://www.alltrails.com/trail/us/colorado/odessa-lake-via-fern-lake-trail--3">
                  Odessa Lake*
                </a>
              </div>
            </div>
          </MDBListGroupItem>
        </MDBListGroup>
      </MDBRow>
      <MDBRow>
        <h5 className="pt-4 text-center section__text">Scenic Drive</h5>
      </MDBRow>
      <h6
        className="text-center section__text"
        variant="body2"
        color="text.secondary"
      >
        Rocky Mountain National Park's Trail Ridge Road takes you all the way
        through the park to the high alpine visitor center. It is a fantastic
        way to experience the natural beauty of the park, and to see plenty of
        wildlife as well.
      </h6>
    </>
  );
}

export function Climbing() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  if (width === 0 || height === 0) {
    return <ClimbingVertical />;
  }
  const ar = width / height;
  if (ar >= 1) {
    return <ClimbingHorizontal />;
  } else if (ar < 1) {
    return <ClimbingVertical />;
  } else {
    return <ClimbingVertical />;
  }
}

function ClimbingList() {
  return (
    <>
      <h5 className="pt-0 text-center section__text">Rock Climbing</h5>
      <MDBRow className="d-flex justify-content-center">
        <h6 className="pt-4 text-center section__text">Bouldering</h6>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
        <h6 className="fw-bold section__text text-center">
          <a href="https://www.mountainproject.com/area/106385805/the-suzuki-boulder">
            Suzuki Boulder*
          </a>
        </h6>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <h6 className="pt-4 text-center section__text">Sport Climbing</h6>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
        <h6 className="fw-bold section__text text-center">
          <a href="https://www.mountainproject.com/area/105744337/jurassic-park-lily-lake">
            Jurassic Park
          </a>
        </h6>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <h6 className="pt-4 text-center section__text">Trad Climbing</h6>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
        <h6 className="fw-bold section__text text-center">
          <a href="https://www.mountainproject.com/area/105744228/lumpy-ridge">
            Lumpy Ridge
          </a>
        </h6>
      </MDBRow>
    </>
  );
}

function ClimbingVertical() {
  return (
    <>
      <MDBRow className="justify-content-center">
        <MDBCol size="8">
          <ClimbingList />
        </MDBCol>
      </MDBRow>
    </>
  );
}

function ClimbingHorizontal() {
  return (
    <>
      <MDBRow className="d-flex justify-content-center align-items-center">
        <MDBCol>
          <figure className="mt-3">
            <img
              src={require("./assets/todo/yikes.jpg")}
              className="img-fluid shadow-4-strong rounded"
              alt="Judah's first time on a rope outside."
            />
            <figcaption className="pt-3 figure-caption section__text text-center">
              Judah's first outdoor climb.
            </figcaption>
          </figure>
        </MDBCol>
        <MDBCol>
          <ClimbingList />
        </MDBCol>
      </MDBRow>
    </>
  );
}

function Itinerary() {
  return (
    <>
      <p className="text-center pt-4 mb-0 section__title_2">
        Example Travel Itinerary
      </p>
      <MDBRow className="mt-0">
        <MDBCol></MDBCol>
        <MDBCol size="12">
          <MDBListGroup numbered>
            <MDBListGroupItem
              noBorders
              style={{ backgroundColor: "transparent" }}
              className="d-flex justify-content-center align-items-start"
            >
              <div className="ms-2 section__text">
                <div className="fw-bold section__text">
                  Fly into denver Friday, 6/21/2024 (Alititude: 5000 ft)
                </div>
                <div>Note: Some hotels in Estes have a two night minimum.</div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem
              noBorders
              style={{ backgroundColor: "transparent" }}
              className="d-flex justify-content-center align-items-start"
            >
              <div className="ms-2 section__text">
                <div className="fw-bold section__text">
                  Drive up to Estes the morning of, 6/22/2024
                </div>
                The drive from DIA takes approximately 90 minutes.
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem
              noBorders
              style={{ backgroundColor: "transparent" }}
              className="d-flex justify-content-center align-items-start"
            >
              <div className="ms-2 section__text">
                <div className="fw-bold section__text">
                  Check into Estes hotel around noon (Altitude: 7523 ft)
                </div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem
              noBorders
              style={{ backgroundColor: "transparent" }}
              className="d-flex justify-content-center align-items-start"
            >
              <div className="ms-2 section__text">
                <div className="fw-bold section__text">
                  Spend the night in Estes and enjoy the scenery!
                </div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem
              noBorders
              style={{ backgroundColor: "transparent" }}
              className="d-flex justify-content-center align-items-start"
            >
              <div className="ms-2 section__text">
                <div className="fw-bold section__text">
                  Drop by the morning farewell breakfast, 6/23/2024
                </div>
              </div>
            </MDBListGroupItem>
            <MDBListGroupItem
              noBorders
              style={{ backgroundColor: "transparent" }}
              className="d-flex justify-content-center align-items-start"
            >
              <div className="ms-2 section__text">
                <div className="fw-bold section__text">
                  Check out of hotel, drive back to DIA in Denver, and fly out!
                </div>
              </div>
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBCol>
        <MDBCol></MDBCol>
      </MDBRow>
    </>
  );
}
