import React from "react";
import { useEffect } from "react";

import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import "./FAQ.css";

import NavBar from "./NavBar";
import Footer from "./Footer";
import { Hikes, Climbing } from "./ThingsToDo";

export default function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqData = [
    {
      question: <p>What date should I RSVP by?</p>,
      answer:
        "Please RSVP by May 15, 2024 using the RSVP link on our wedding website.",
    },
    {
      question: <p>How do I get to Estes Park?</p>,
      answer: (
        <p>
          The easiest way to get to Estes Park is by renting a car. There is a{" "}
          <a
            href="https://www.estesparkshuttle.com/DIA-airport-shuttle"
            className="text-green-600 dark:text-green-500 hover:underline hover:text-green-800"
          >
            shuttle from DIA
          </a>
          , however there are no Ubers in Estes Park. There is{" "}
          <a
            href="https://www.visitestespark.com/plan/getting-around/free-shuttle-buses/"
            className="text-green-600 dark:text-green-500 hover:underline hover:text-green-800"
          >
            free transportation in town
          </a>
          , however it does not run to the venue directly. If you choose to take
          the DIA shuttle into town, please reach out to Judah and Alexandria to
          coordinate a ride to the venue.
        </p>
      ),
    },
    {
      question: (
        <p>
          What does 'formal black attire' mean? In other words, what should I
          wear?
        </p>
      ),
      answer: (
        <p>
          We kindly ask that men wear black or dark colored suits. We ask that
          women wear formal black dresses of any length, jumpsuits, or any other
          formal black attire they feel comfortable dancing in.
        </p>
      ),
    },
    {
      question: <p>What kind of weather does Estes Park have?"</p>,
      answer: (
        <p>
          Estes Park sits at an elevation of 7,522 feet, and as a result, June
          weather is quite mild with highs in the 70s and lows in the 40s. Both
          the ceremony and the reception will be indoors, however we recommend
          bringing a light jacket or shawl for the evening.
        </p>
      ),
    },
    {
      question: <p>Will there be an open bar?</p>,
      answer: (
        <p>
          The open bar will serve beer and wine options, however cocktails may
          be purchased. We ask that you keep in mind that one drink at altitude
          is the equivalent of two drinks at sea level, so please drink
          responsibly.
        </p>
      ),
    },
    {
      question: <p>What time should I arrive at the venue?</p>,
      answer: (
        <p>
          The ceremony will begin at 4 PM, so we ask that you arrive between
          3:30-4:00 PM.
        </p>
      ),
    },
    {
      question: <p>What time will the reception end?</p>,
      answer: <p>The reception will end at 10:30 PM.</p>,
    },
    {
      question: <p>Am I allowed to take photos at the wedding?</p>,
      answer: (
        <p>
          We will have two photographers on site for the full duration of the
          wedding. We ask that you refrain from taking photos during the
          ceremony, but welcome any and all photos at the reception.
        </p>
      ),
    },
    {
      question: <p>Where should I stay?</p>,
      answer: (
        <div className="container">
          <p className="text-center pt-5 pb-0 section__title">
            Lodging on-site
          </p>
          <hr className="hr hr-blurry" />
          <div className="pt-3 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <a href="https://blackcanyoninn.com/">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/babcinn.jpg")}
                  alt="Boulders at Black Canyon Inn"
                />
              </a>
              <div className="card-body">
                <h5 className="text-center section__text">
                  Boulders at Black Canyon Inn
                </h5>
                <h6 className="text-center section__text">
                  Suite rentals are available with 1, 2, and 3 bedrooms. Suites
                  can be booked out as soon as now.
                </h6>
              </div>
            </div>
          </div>

          <p className="text-center pt-5 pb-0 section__title">
            Lodging off-site
          </p>
          <hr className="hr hr-blurry" />
          <div className="pt-3 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <a href="https://www.stanleyhotel.com/accommodations.html">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/stanley.jpg")}
                  alt="The Stanley Hotel"
                />
              </a>
              <div className="card-body">
                <h5 className="text-center section__text">The Stanley Hotel</h5>
                <h6 className="text-center section__text">
                  There is a two night minimum at the historic Stanley where
                  Stephen King became inspired to write the Shining.
                </h6>
              </div>
            </div>
          </div>

          <div className="pt-5 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <a href="https://www.fallrivervillage.com/?utm_source=google&utm_campaign=local&utm_medium=organic">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/fallriver.jpg")}
                  alt="Fall River Village Resort"
                />
              </a>
              <div className="card-body">
                <h5 className="text-center section__text">
                  Fall River Village Resort
                </h5>
                <h6 className="text-center section__text">
                  Offers accomodations within throwing distance from downtown
                  Estes Park and Rocky Mountain National Park. It doesn't take
                  much luck to open your door to a pack of Mule Deer or Elk
                  passing through the property.
                </h6>
              </div>
            </div>
          </div>

          <div className="pt-5 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("./assets/todo/airbnbvrbo.jpg")}
                alt="AriBnB/VRBO"
              />
              <div className="card-body">
                <div className="text-center section__text">
                  <a
                    href="https://www.airbnb.com/"
                    className="text-green-600 dark:text-green-500 hover:underline hover:text-green-800"
                  >
                    AirBnB
                  </a>{" "}
                  &{" "}
                  <a
                    href="https://www.vrbo.com/"
                    className="text-green-600 dark:text-green-500 hover:underline hover:text-green-800"
                  >
                    VRBO
                  </a>
                </div>
                <div className="text-center section__text">
                  If renting through Airbnb/VRBO you'll want to make sure the
                  unit is actually in Estes Park. Sometimes the search engine
                  thinks that Rocky Mountain National Park (a few minutes drive
                  away) is where you want to be and will show you units near
                  Grand Lake.
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      question: <p>Where should I eat?</p>,
      answer: (
        <div className="container">
          <p className="text-center pt-3 pb-0 section__subtitle">Breakfast</p>
          <div className="pt-3 pb-5 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <a href="https://www.thenotchtop.com/">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/notchtop.jpg")}
                  alt="Notchtop"
                />
              </a>
              <div className="card-body">
                <h5 className="text-center section__text">Notchtop</h5>
              </div>
            </div>
          </div>

          <p className="text-center pt-3 pb-0 section__subtitle">Lunch</p>
          <div className="pb-0 pb-5 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <a href="https://bossburgersandgyros.com/">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/boss.jpg")}
                  alt="Boss Burgers and Gyros"
                />
              </a>
              <div className="card-body">
                <h5 className="text-center section__text">
                  Boss Burgers and Gyros
                </h5>
              </div>
            </div>
          </div>

          <p className="text-center pt-3 pb-0 section__subtitle">Dinner</p>
          <div className="pb-3 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "512px" }}
            >
              <a href="https://www.birdandjim.com/">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/bird.jpg")}
                  alt="Bird & Jim"
                />
              </a>
              <div className="card-body">
                <h5 className="text-center section__text">Bird & Jim</h5>
                <h6 className="text-center section__text">
                  We highly recommend that you make reservations if you have a
                  party of four or more. It's sometimes difficult for Bird & Jim
                  to seat large groups due to the popularity of the restaurant.
                </h6>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      question: <p>What things can I do in Estes Park or nearby?</p>,
      answer: (
        <div className="container">
          <div className="pb-3 d-flex justify-content-center">
            <div
              className="card"
              style={{ backgroundColor: "#fffaf3", maxWidth: "1024px" }}
            >
              <a href="https://www.nps.gov/romo/planyourvisit/fees.htm">
                <img
                  style={{ width: "100%", objectFit: "cover" }}
                  src={require("./assets/todo/rmnp.jpg")}
                  alt="Rocky Mountain National Park"
                />
              </a>
              <div className="card-body">
                <h4 className="text-center section__text">
                  Rocky Mountain National Park
                </h4>
                <h6
                  variant="body2"
                  color="text.secondary"
                  className="pb-3 text-center section__text"
                >
                  Visiting the park is an absolute must while staying in Estes.
                  Arguably there is no better place in Colorado to be closer to
                  the great outdoors. Regardless of where you are in RMNP you're
                  bound to come face to face with wildlife at one point or
                  another. Use common sense and don't get too close to the moose
                  or elk! Make sure to bring plenty of water, dehydration hits
                  substantially quicker at higher altitudes. Also, bring rain
                  jacket, preferably one that is windproof. While it does not
                  rain much at any given time, afternoon storms typically occur
                  in the early afternoon and may bring some wind and light rain.
                </h6>

                <hr className="hr hr-blurry m-0" />

                <Hikes />

                <hr className="hr hr-blurry" />

                <Climbing />

                <h6
                  className="text-center section__text"
                  variant="body2"
                  color="text.secondary"
                  style={{ fontWeight: "bold", m: 1 }}
                >
                  * All the activities marked by an asterisk require that you
                  purchase a day pass to RMNP.
                </h6>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      question: (
        <p>I have more questions about the wedding, who can I contact?</p>
      ),
      answer: <p>Please reach out to Alexandria via text at (919)923-3447.</p>,
    },
  ];

  const faqElements = faqData.map((item) => (
    <AccordionItem>
      <h2>
        <AccordionButton _expanded={{ bg: "#3c3d33", color: "white" }}>
          <Box as="span" flex="1" textAlign="left">
            <div className="flex flex-row font-bold">{item.question}</div>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} className="text-left">
        {item.answer}
      </AccordionPanel>
    </AccordionItem>
  ));

  return (
    <div className="bg-background-gradient-end h-dvh">
      <NavBar />
      <div className="py-4 text-center bg-background-gradient-start">
        <h1 className="faq_title">Frequently Asked Questions</h1>
      </div>
      <div className="bg-background-gradient-end">
        <Accordion
          defaultIndex={[0]}
          allowMultiple={true}
          className="m-0 sm:px-4 md:12 pb-10 bg-gradient-to-b from-background-gradient-start to-background-gradient-end"
          fontFamily={"'Quattrocento', serif"}
        >
          {faqElements}
        </Accordion>
        <div className="h-16"></div>
      </div>

      <Footer />
    </div>
  );
}
