import { useEffect } from "react";

import "./WebsiteGallery.css";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import Footer from "./Footer";
import NavBar from "./NavBar";

import { LightgalleryItem } from "react-lightgallery";

function compute_num_cols() {
  let w = window.innerWidth;
  let h = window.innerHeight;
  if (w <= 0 || h <= 0) {
    return 3;
  }
  const ar = w / h;
  if (ar >= 1) {
    return 5;
  } else if (ar <= 1) {
    return 3;
  } else {
    return 3;
  }
}

export default function WebsiteGallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Doesn't react to window resizing yet.
  const cols = compute_num_cols();

  return (
    <div
      className="gallery_background_gradient"
      style={
        {
          // background: "linear-gradient(rgba(226,214,198,.5),transparent)",
          // backgroundColor: "rgb(255,250,243)" /*this your primary color*/
          // background: "linear-gradient(red, blue)" /*this your primary color*/
        }
      }
    >
      <NavBar />

      {/* Jumbotron */}
      <div className="pt-4 text-center">
        <h1 className="mb-1 gallery_title">From The Sea to The Summit</h1>
      </div>
      <hr className="hr hr-blurry" />

      <div className="container-fluid pb-5 mb-0">
        <div className="row dflex justify-content-center">
          <div className="col dflex justify-content-center ms-1 p-0">
            <div className="row m-0">
              <Box sx={{ width: 1, height: 1, overflowY: "hidden" }}>
                <ImageList variant="masonry" cols={cols} gap={8}>
                  {itemData().map((item) => (
                    <LightgalleryItem src={item.img}>
                      <ImageListItem key={item.img}>
                        <img
                          className="lazy-load-image-background blue lazy-load-image-loaded"
                          style={{
                            color: "transparent",
                            display: "inline-block",
                          }}
                          src={`${item.img}?w=248&fit=crop&auto=format`}
                          srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.title}
                          loading="lazy"
                          srl_gallery_image="true"
                        />
                      </ImageListItem>
                    </LightgalleryItem>
                  ))}
                </ImageList>
              </Box>
            </div>
          </div>
        </div>
      </div>

      <hr className="hr hr-blurry mb-5 mt-0" />

      <Footer />
    </div>
  );
}

const itemData = () => {
  // let data = [];
  // const nphotos = 53;
  // for (let i = 0; i < nphotos; i++) {
  //   const item_path = "./assets/photos/gallery-" + i.toString() + ".jpg";
  //   const item = {
  //     img: require(item_path),
  //     title: i.toString(),
  //   };
  //   data.push(item);
  // }
  // return data;

  return [
    {
      img: require("./assets/photos/gallery-0.jpg"),
      title: "1",
    },
    {
      img: require("./assets/photos/gallery-1.jpg"),
      title: "1",
    },
    {
      img: require("./assets/photos/gallery-2.jpg"),
      title: "2",
    },
    {
      img: require("./assets/photos/gallery-3.jpg"),
      title: "3",
    },
    {
      img: require("./assets/photos/gallery-4.jpg"),
      title: "4",
    },
    {
      img: require("./assets/photos/gallery-5.jpg"),
      title: "5",
    },
    {
      img: require("./assets/photos/gallery-6.jpg"),
      title: "6",
    },
    {
      img: require("./assets/photos/gallery-7.jpg"),
      title: "7",
    },
    {
      img: require("./assets/photos/gallery-8.jpg"),
      title: "8",
    },
    {
      img: require("./assets/photos/gallery-9.jpg"),
      title: "9",
    },
    {
      img: require("./assets/photos/gallery-11.jpg"),
      title: "11",
    },
    {
      img: require("./assets/photos/gallery-12.jpg"),
      title: "12",
    },
    {
      img: require("./assets/photos/gallery-13.jpg"),
      title: "13",
    },
    {
      img: require("./assets/photos/gallery-14.jpg"),
      title: "14",
    },
    {
      img: require("./assets/photos/gallery-15.jpg"),
      title: "15",
    },
    {
      img: require("./assets/photos/gallery-16.jpg"),
      title: "16",
    },
    {
      img: require("./assets/photos/gallery-17.jpg"),
      title: "17",
    },
    {
      img: require("./assets/photos/gallery-18.jpg"),
      title: "18",
    },
    {
      img: require("./assets/photos/gallery-19.jpg"),
      title: "19",
    },
    {
      img: require("./assets/photos/gallery-20.jpg"),
      title: "20",
    },
    {
      img: require("./assets/photos/gallery-21.jpg"),
      title: "21",
    },
    {
      img: require("./assets/photos/gallery-22.jpg"),
      title: "22",
    },
    {
      img: require("./assets/photos/gallery-23.jpg"),
      title: "23",
    },
    {
      img: require("./assets/photos/gallery-24.jpg"),
      title: "24",
    },
    {
      img: require("./assets/photos/gallery-25.jpg"),
      title: "25",
    },
    {
      img: require("./assets/photos/gallery-26.jpg"),
      title: "26",
    },
    {
      img: require("./assets/photos/gallery-27.jpg"),
      title: "27",
    },
    {
      img: require("./assets/photos/gallery-28.jpg"),
      title: "28",
    },
    {
      img: require("./assets/photos/gallery-29.jpg"),
      title: "29",
    },
    {
      img: require("./assets/photos/gallery-31.jpg"),
      title: "31",
    },
    {
      img: require("./assets/photos/gallery-32.jpg"),
      title: "32",
    },
    {
      img: require("./assets/photos/gallery-33.jpg"),
      title: "33",
    },
    {
      img: require("./assets/photos/gallery-34.jpg"),
      title: "34",
    },
    {
      img: require("./assets/photos/gallery-35.jpg"),
      title: "35",
    },
    {
      img: require("./assets/photos/gallery-36.jpg"),
      title: "36",
    },
    {
      img: require("./assets/photos/gallery-37.jpg"),
      title: "37",
    },
    {
      img: require("./assets/photos/gallery-38.jpg"),
      title: "38",
    },
    {
      img: require("./assets/photos/gallery-39.jpg"),
      title: "39",
    },
    {
      img: require("./assets/photos/gallery-40.jpg"),
      title: "40",
    },
    {
      img: require("./assets/photos/gallery-41.jpg"),
      title: "41",
    },
    {
      img: require("./assets/photos/gallery-42.jpg"),
      title: "42",
    },
    {
      img: require("./assets/photos/gallery-43.jpg"),
      title: "43",
    },
    {
      img: require("./assets/photos/gallery-44.jpg"),
      title: "44",
    },
    {
      img: require("./assets/photos/gallery-45.jpg"),
      title: "45",
    },
    {
      img: require("./assets/photos/gallery-46.jpg"),
      title: "46",
    },
    {
      img: require("./assets/photos/gallery-47.jpg"),
      title: "47",
    },
    {
      img: require("./assets/photos/gallery-48.jpg"),
      title: "48",
    },
    {
      img: require("./assets/photos/gallery-49.jpg"),
      title: "49",
    },
    {
      img: require("./assets/photos/gallery-50.jpg"),
      title: "50",
    },
    {
      img: require("./assets/photos/gallery-51.jpg"),
      title: "51",
    },
    {
      img: require("./assets/photos/gallery-52.jpg"),
      title: "52",
    },
  ];
};
