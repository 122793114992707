import { useEffect } from "react";

import {
  Center,
  Stack,
  Image,
  Text,
  Heading,
  Button,
  Card,
  CardBody,
  CardFooter,
  SimpleGrid,
} from "@chakra-ui/react";

import "./Registry2.css";

import NavBar from "./NavBar";
import Footer from "./Footer";

export default function Registry2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="pt-2 pb-2 px-3 text-center d-flex row justify-content-center">
        <Text
          py={4}
          fontSize={{ base: "4xl", md: "6xl" }}
          className="mb-1 registry_title"
        >
          Wedding Registry
        </Text>
        <Text
          w={{ base: "100%", md: "75%" }}
          // letterSpacing={{ base: "0", md: "0.1em" }}
          lineHeight={{ base: "6", md: "7" }}
          className="registry__section__text lh-lg"
          style={{ textJustify: "inter-word", textAlign: "center" }}
        >
          Your presence is enough of a present to us! If you are still inclined,
          please consider the following registry options for wedding gifts.
        </Text>
      </div>
      <div className="py-2">
        <hr className="hr hr-blurry" />
      </div>
      <Center className="w-12/12 p-12">
        <SimpleGrid spacing={10} columns={{ lg: 3, md: 1 }}>
          <Card
            direction={{ base: "column", sm: "column" }}
            overflow="hidden"
            variant="outline"
            boxShadow={"xl"}
          >
            <Image
              objectFit="fill"
              maxW={{ base: "100%", sm: "100%" }}
              src={require("./assets/registry/portugal_coastline.jpg")}
              alt="Caffe Latte"
            />
            <Stack height={"100%"}>
              <CardBody>
                <Heading
                  size="lg"
                  letterSpacing={2}
                  fontFamily={"Quattrocento, serif"}
                  py={2}
                >
                  Honeymoon Registry
                </Heading>
                <Text py="2" className="text-justify card_text">
                  We're traveling to Portugal for our honeymoon in August 2024.
                  We've planned a two week adventure spanning northern wine
                  country down to the magnificent Algarve coast.
                </Text>
                <Text py="2" className="text-justify card_text">
                  We would love a contribution towards our planned activites.
                  Contributions can be made via Venmo to avoid a 2.5% credit
                  card processing fee.
                </Text>
              </CardBody>

              <CardFooter justifyContent={{ base: "center", md: "left" }}>
                <a href="https://withjoy.com/alexandria-and-judah/registry">
                  <Button
                    variant="solid"
                    colorScheme="hipo"
                    style={{
                      fontFamily: ["Quattrocento", "serif"],
                      letterSpacing: 2,
                    }}
                  >
                    Gift Memories
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>
          <Card
            direction={{ base: "column", sm: "column" }}
            overflow="hidden"
            variant="outline"
            boxShadow={"xl"}
          >
            <Image
              objectFit="fill"
              maxW={{ base: "100%", sm: "100%" }}
              src={require("./assets/registry/traditional_gifts.jpg")}
              alt="Caffe Latte"
            />
            <Stack>
              <CardBody>
                <Heading
                  size="lg"
                  letterSpacing={2}
                  fontFamily={"Quattrocento, serif"}
                  py={2}
                >
                  Traditional Registry
                </Heading>
                <Text py="2" className="text-justify card_text">
                  As many of you know, we love to host friends and family. We've
                  put together this registry through Crate & Barrel with items
                  to enhance our cooking and cocktail creations.
                </Text>
                <Text py="2" className="text-justify card_text">
                  If you find comparable items through alternative vendors with
                  competitive prices, please send them our way. But do let us
                  know so that we can mark the Crate & Barrel items as "gifted".
                </Text>
              </CardBody>
              <CardFooter justifyContent={{ base: "center", md: "left" }}>
                <a href="https://www.crateandbarrel.com/gift-registry/alexandria-podolski/r7060063">
                  <Button
                    variant="solid"
                    colorScheme="hipo"
                    style={{
                      fontFamily: ["Quattrocento", "serif"],
                      letterSpacing: 2,
                    }}
                  >
                    Gift Home Goods
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>
          <Card
            direction={{ base: "column", sm: "column" }}
            overflow="hidden"
            variant="outline"
            boxShadow={"xl"}
          >
            <Image
              objectFit="fill"
              maxW={{ base: "100%", sm: "100%" }}
              src={require("./assets/registry/record.jpg")}
              alt="Caffe Latte"
            />
            <Stack>
              <CardBody>
                <Heading
                  size="lg"
                  letterSpacing={2}
                  fontFamily={"Quattrocento, serif"}
                  py={2}
                >
                  Personalized Gifts
                </Heading>
                <Text py="2" className="text-justify card_text">
                  If you're looking to send a gift with a bit more of a personal
                  touch, please consider sending a copy of your favorite album
                  on record, or your favorite cookbook.
                </Text>
                <Text py="2" className="text-justify card_text">
                  We've been slowly building both our record and recipe
                  collection over the years and welcome a gift that will make us
                  think fondly of you during our day to day lives.
                </Text>
              </CardBody>
            </Stack>
          </Card>
        </SimpleGrid>
      </Center>
      <div className="py-2">
        <hr className="hr hr-blurry" />
      </div>
      <div className="pt-2 px-3 pb-20 text-center d-flex row justify-content-center">
        <Text
          w={{ base: "100%", md: "75%" }}
          // letterSpacing={{ base: "0", md: "0.1em" }}
          lineHeight={{ base: "6", md: "7" }}
          className="registry__section__text lh-lg"
          style={{ textJustify: "inter-word", textAlign: "center" }}
        >
          Please send all gifts to Judah & Alexandria at
        </Text>
        <Text
          w={{ base: "100%", md: "75%" }}
          // letterSpacing={{ base: "0", md: "0.1em" }}
          lineHeight={{ base: "6", md: "7" }}
          className="registry__section__text lh-lg"
          style={{ textJustify: "inter-word", textAlign: "center" }}
        >
          4724 South Lewis Circle, Littleton, CO 80127
        </Text>
      </div>
      <Footer />
    </>
  );
}
