import { useEffect } from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";

import { MDBRow, MDBCol } from "mdb-react-ui-kit";

import "./Story.css";

export default function Story() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const width = window.innerWidth;
  const height = window.innerHeight;
  var n_cols = "7";
  if (width > 0 && height > 0) {
    const ar = width / height;
    if (ar < 1) {
      n_cols = "10";
      return <StoryVertical n_cols={n_cols} />;
    } else {
      return <StoryHorizontal n_cols={n_cols} />;
    }
  } else {
    return <StoryHorizontal n_cols={n_cols} />;
  }
}

function StoryHorizontal(props) {
  return (
    <div className="story_background_gradient" style={{}}>
      <NavBar />
      <h1 className="text-center pt-4 pb-0 story__title">Our Story</h1>
      <hr className="hr hr-blurry" />

      <MDBRow className="pt-3 mb-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            align="left"
            style={{
              width: "250px",
              height: "auto",
              margin: "10px 10px 0px 0px",
            }}
            src={require("./assets/story/grandfather.jpg")}
            alt="Made it out alive!"
          />

          <p className="story__text">
            Our story started in the stairwell of Applied Research Associates
            (ARA) in Raleigh. Alexandria noticed Judah’s black chrome boots and
            said “Nice shoes!” before continuing on her way, without thinking
            much of it. They were cool shoes after all, and she was well known
            to hand out compliments for just about anything that caught her eye.
            It only got weird when a few months later she said the same thing,
            in the same stairwell, about the same shoes. Naturally Judah thought
            she was a spy, especially since he’d been looking for her around ARA
            since their first encounter.
          </p>
          <p className="story__text">
            We had our first real opportunity to get to know each other at an
            Enhanced Leadership Conference, where we were both presenting.
            Conversation flowed easily. Alexandria thought she’d made a great
            friend. Judah thought Alexandria might be the one. Little did they
            know, they were both right.
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow className="my-0 pt-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            align="right"
            style={{
              width: "250px",
              height: "auto",
              margin: "10px 0px 0px 10px",
            }}
            src={require("./assets/story/ocean_isle.jpg")}
            alt="Made it out alive!"
          />
          <p className="story__text">
            Fast forward over a year later when we started dating. Our
            relationship quickly blossomed, until abruptly Alexandria found out
            she’d be relocating to ARA’s Colorado office. Alexandria thought she
            was easing into a breakup conversation, saying something like “So
            the past couple of months have been wonderful, but I got a job
            opportunity out in Colorado. I’ve done long distance before and I’m
            not really interested in doing that again.”
          </p>
          <p className="story__text">
            Judah had other ideas and responded effortlessly, “That makes sense.
            I hate long distance too. But I’ve always wanted to move out west!”
          </p>
          <p className="story__text">
            Within a few months, both of us had moved out to Colorado and then
            the real adventures began. Casual hikes quickly turned into
            summiting mountains. Road trips around Denver evolved into long
            weekends exploring Utah’s National Parks. We skied, biked, climbed,
            and hiked our way through our first year in Colorado. We settled
            into a new normal, and started getting a sense of what forever could
            look like. Fall of 2021, Judah proposed to Alexandria, taking that
            first step to make that potential forever a reality.
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-0 mt-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            align="left"
            style={{
              width: "250px",
              height: "auto",
              margin: "10px 10px 0px 0px",
            }}
            src={require("./assets/story/torreys.jpg")}
            alt="Made it out alive!"
          />
          <p className="story__text">
            Since our engagement, adventures have continued. Judah started a new
            job January 2022. Once he settled into his new role at Agenus, we
            were finally ready to start the adventure of planning our wedding,
            only to realize that the post-covid wedding scene was a nightmare.
            Cue a Summer 2024 wedding!
          </p>
          <p className="story__text">
            We’ve taken advantage of the extra time to plan and can’t wait to
            celebrate with you all! Hopefully the memories last our lifetime.
          </p>
        </MDBCol>
      </MDBRow>

      <hr className="hr hr-blurry" />

      <MDBRow className="py-3 mt-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            align="left"
            style={{
              width: "100%",
              height: "auto",
              margin: "0px 10px 0px 0px",
            }}
            src={require("./assets/story/bryce.jpg")}
            alt="Made it out alive!"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-0 pb-2">
        <hr className="hr hr-blurry" />
      </MDBRow>

      <Footer />
    </div>
  );
}

function StoryVertical(props) {
  return (
    <div className="story_background_gradient" style={{}}>
      <NavBar />
      <h1 className="text-center pt-4 pb-0 story__title">Our Story</h1>
      <hr className="hr hr-blurry" />

      <MDBRow className="pt-3 mb-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            style={{ width: "100%", height: "auto" }}
            src={require("./assets/story/grandfather.jpg")}
            alt="Made it out alive!"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-3 mb-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <p className="story__text">
            Our story started in the stairwell of Applied Research Associates
            (ARA) in Raleigh. Alexandria noticed Judah’s black chrome boots and
            said “Nice shoes!” before continuing on her way, without thinking
            much of it. They were cool shoes after all, and she was well known
            to hand out compliments for just about anything that caught her eye.
            It only got weird when a few months later she said the same thing,
            in the same stairwell, about the same shoes. Naturally Judah thought
            she was a spy, especially since he’d been looking for her around ARA
            since their first encounter.
          </p>
          <p className="story__text">
            We had our first real opportunity to get to know each other at an
            Enhanced Leadership Conference, where we were both presenting.
            Conversation flowed easily. Alexandria thought she’d made a great
            friend. Judah thought Alexandria might be the one. Little did they
            know, they were both right.
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-3 mb-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            style={{ width: "100%", height: "auto" }}
            src={require("./assets/story/ocean_isle.jpg")}
            alt="Made it out alive!"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="my-0 pt-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <p className="story__text">
            Fast forward over a year later when we started dating. Our
            relationship quickly blossomed, until abruptly Alexandria found out
            she’d be relocating to ARA’s Colorado office. Alexandria thought she
            was easing into a breakup conversation, saying something like “So
            the past couple of months have been wonderful, but I got a job
            opportunity out in Colorado. I’ve done long distance before and I’m
            not really interested in doing that again.”
          </p>
          <p className="story__text">
            Judah had other ideas and responded effortlessly, “That makes sense.
            I hate long distance too. But I’ve always wanted to move out west!”
          </p>
          <p className="story__text">
            Within a few months, both of us had moved out to Colorado and then
            the real adventures began. Casual hikes quickly turned into
            summiting mountains. Road trips around Denver evolved into long
            weekends exploring Utah’s National Parks. We skied, biked, climbed,
            and hiked our way through our first year in Colorado. We settled
            into a new normal, and started getting a sense of what forever could
            look like. Fall of 2021, Judah proposed to Alexandria, taking that
            first step to make that potential forever a reality.
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-3 mb-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            style={{ width: "100%", height: "auto" }}
            src={require("./assets/story/torreys.jpg")}
            alt="Made it out alive!"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-0 mt-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <p className="story__text">
            Since our engagement, adventures have continued. Judah started a new
            job January 2022. Once he settled into his new role at Agenus, we
            were finally ready to start the adventure of planning our wedding,
            only to realize that the post-covid wedding scene was a nightmare.
            Cue a Summer 2024 wedding!
          </p>
          <p className="story__text">
            We’ve taken advantage of the extra time to plan and can’t wait to
            celebrate with you all! Hopefully the memories last our lifetime.
          </p>
        </MDBCol>
      </MDBRow>

      <hr className="hr hr-blurry" />

      <MDBRow className="py-3 mt-0 d-flex justify-content-center">
        <MDBCol size={props.n_cols}>
          <img
            className="rounded shadow-4-strong"
            style={{ width: "100%", height: "auto" }}
            src={require("./assets/story/bryce.jpg")}
            alt="Made it out alive!"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="pt-0 pb-2">
        <hr className="hr hr-blurry" />
      </MDBRow>

      <Footer />
    </div>
  );
}
