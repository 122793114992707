import "./Login.css";
import React, { useState, useEffect } from "react";
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import Cookies from "universal-cookie";

import { DataStore } from "aws-amplify";
import { Subscriber } from "./models";

function validate_email(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

async function persist_subscriber(email) {
  try {
    const subscribers = await DataStore.query(Subscriber, (c) =>
      c.email.eq(email)
    );
    console.log(subscribers);
    if (subscribers.length === 0) {
      await DataStore.save(
        new Subscriber({
          email: email,
        })
      );
      console.log("subscriber saved successfully!");
    } else {
      console.log("subscriber not saved because subscriber already exists.");
    }
  } catch (error) {
    console.log("Error saving subscriber", error);
  }
}

export default function Login(props) {
  // Modal settings
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenInvalidEmail,
    onOpen: onOpenInvalidEmail,
    onClose: onCloseInvalidEmail,
  } = useDisclosure();

  // Sets the state of the password when the user updates the password field
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [sync, setSync] = useState(false);

  const access_key_id = process.env.REACT_APP_ACCESS_KEY_ID;
  const secret_access_key = process.env.REACT_APP_SECRET_ACCESS_KEY;

  useEffect(() => {
    async function syncDataStore() {
      await DataStore.stop();
      await DataStore.start();
    }

    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!sync) {
      syncDataStore();
      setSync(true);
    }
  }, []);

  // Retrieve the password from aws
  async function handleLoginAttempt() {
    const secret_name = "hipo_adventures_pizza_password";
    const client = new SecretsManagerClient({
      region: "us-east-2",
      credentials: {
        accessKeyId: access_key_id,
        secretAccessKey: secret_access_key,
      },
    });
    let response;
    try {
      response = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
        })
      );
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }

    const expected_password = JSON.parse(response.SecretString)[secret_name];

    // Check that email is valid
    if (!validate_email(email)) {
      onOpenInvalidEmail();
    }

    // Check that the password the user provided is correct
    if (password !== expected_password) {
      onOpen();
    }

    if (password === expected_password && validate_email(email)) {
      await persist_subscriber(email);
      props.setAuth(true);
      const cookies = new Cookies();
      var expriration_date = new Date();
      var time = expriration_date.getTime();
      time += 3600 * 1000;
      expriration_date.setTime(time);
      cookies.remove("auth");
      cookies.set("auth", "true", { path: "/", expires: expriration_date });
    }
  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.code === "Enter") {
      handleLoginAttempt();
    }
  };

  return (
    <section className="vh-100" style={{ backgroundColor: "#fffef5" }}>
      <MDBContainer className="py-5">
        <MDBCard>
          <MDBRow className="g-0 m-0">
            <MDBCol md="6">
              <MDBCardImage
                src={require("./assets/photos/Judah-0188-_CBP0968.jpg")}
                alt="login form"
                className="rounded-start w-100"
              />
            </MDBCol>
            <MDBCol center md="6">
              <MDBCardBody className="d-flex flex-column">
                <h5
                  className="fw-normal my-4 pb-3 login__title text-center"
                  style={{ letterSpacing: "1px" }}
                >
                  Judah & Alexandria
                </h5>
                <MDBInput
                  wrapperClass="mb-4"
                  label="Password"
                  id="formControlLg"
                  type="password"
                  size="lg"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeypress}
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email for wedding updates"
                  id="typeEmail"
                  type="email"
                  size="lg"
                  onChange={(e) => setEmail(e.target.value)}
                  onSubmit={() => handleLoginAttempt()}
                  onKeyPress={handleKeypress}
                />
                <div
                  className="mb-4 p-2 ripple ripple-surface bg-button-green hover:bg-button-hover-green text-white text-center rounded-lg font-semibold"
                  role="button"
                  type="submit"
                  onClick={() => handleLoginAttempt()}
                >
                  Login
                </div>
                <p className="small text-muted mb-0" href="#!">
                  Don't know the password?
                </p>
                <p className="mb-5 pb-lg-2">Contact the bride or groom.</p>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
      <Modal
        isCentered
        onClose={onCloseInvalidEmail}
        isOpen={isOpenInvalidEmail}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invalid Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>The email you entered is not a valid email address.</p>
            <p>Enter a valid email and try loggin in again.</p>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary text-white "
              mr={3}
              onClick={onCloseInvalidEmail}
            >
              Close
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="scale">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Password incorrect</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>The password you entered was incorrect, please try it again.</p>
            <p>
              If you continue to have trouble logging in reach out to Judah.
            </p>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary text-white "
              mr={3}
              onClick={onClose}
            >
              Close
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </section>
  );
}
