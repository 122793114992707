// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { OtherGuest, Subscriber, Child, PlusOne, Guest } = initSchema(schema);

export {
  OtherGuest,
  Subscriber,
  Child,
  PlusOne,
  Guest
};