import "./NavBar.css";

import React from "react";

import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: "#fffaf3" }}
    >
      <div className="container-fluid">
        <NavLink to="/" className="pt-1 navbar__title">
          JUDAH & ALEXANDRIA
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink to="/" className="nav-link navbar__item visible">
                Home
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/schedule" className="nav-link">
                Schedule
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                to="/weekenditinerary"
                className="nav-link navbar__item visible"
              >
                Weekend Itinerary
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink to="/story" className="nav-link navbar__item visible">
                Our Story
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/gallery" className="nav-link navbar__item visible">
                Gallery
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/thingstodo"
                className="nav-link navbar__item visible"
              >
                Things to Do
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink to="/schedule" className="nav-link navbar__item visible">
                Schedule
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/registry" className="nav-link navbar__item visible">
                Registry
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/faq"
                className="nav-link navbar__item visible"
              >
                FAQ
              </NavLink>
            </li>
          </ul>

          {/* <NavLink
            to="/rsvp"
            className="text-white btn btn-primary my-3 mx-3 visible"
          >
            RSVP
          </NavLink> */}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
