import "./App.css";

import { Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import Cookies from "universal-cookie";

import Home from "./Home";
import WebsiteGallery from "./WebsiteGallery";
// import Registry from "./Registry";
import Login from "./Login";
// import RSVP from "./RSVP";
import Story from "./Story";
// import ThingsToDo from "./ThingsToDo";
import FAQ from "./FAQ";
// import WeekendItinerary from "./WeekendItinerary";
import Registry2 from "./Registry2";
import Schedule from "./Schedule"

function App() {
  const [auth, setAuth] = useState(false);

  const cookies = new Cookies();
  const already_authenticated = cookies.get("auth");
  // Handle case where user has already logged in
  if (already_authenticated === "true" && auth === false) {
    setAuth(true);
  }

  if (auth === false) {
    return <Login setAuth={setAuth} />;
  } else {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<WebsiteGallery />} />
        <Route path="/story" element={<Story />} />
        {/* <Route path="/thingstodo" element={<ThingsToDo />} /> */}
        {/* <Route path="/rsvp" element={<RSVP />} /> */}
        <Route path="/faq" element={<FAQ />} />
        {/* <Route path="/weekenditinerary" element={<WeekendItinerary />} /> */}
        <Route path="/registry" element={<Registry2 />} />
        <Route path="/schedule" element={<Schedule />} />
      </Routes>
    );
  }
}

export default App;
