import { useEffect } from "react";

import "./Home.css";

import NavBar from "./NavBar";
import Footer from "./Footer";

import { MDBRow } from "mdb-react-ui-kit";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="home_background_gradient"
      style={
        {
          // backgroundColor: "#e2d6c6"
        }
      }
    >
      <NavBar />
      <MDBRow className="g-0 m-0">
        <div
          id="intro"
          className="bg-image mb-5 shadow-2-strong home__background__image"
        ></div>
      </MDBRow>
      <h1 className="text-center my-0 title_">JUDAH</h1>
      <h1 className="text-center my-5 title_">&</h1>
      <h1 className="text-center my-5 title_">ALEXANDRIA</h1>
      <div className="flex flex-row justify-center gap-4"></div>
      <div class="relative flex items-center">
        <div class="flex-grow border-t border-black ml-10"></div>
        <p className="text-center header__ px-3">SATURDAY</p>
        <div class="flex-grow border-t border-black mr-10"></div>
      </div>
      <p className="text-center date__">June 22, 2024</p>
      <p className="text-center mb-0 mt-5 section__title">THE BOULDERS</p>
      <p className="text-center mb-1 section__title mx-4">
        at BLACK CANYON INN
      </p>
      <p className="text-center mt-0 address__">800 MacGregor Ave</p>
      <p className="text-center mb-5 address__">Estes Park, CO 80517</p>
      <hr className="hr hr-blurry" />

      <div className="container my-5 w-75" style={{ maxWidth: "800px" }}>
        <p className="text-center timeline__">DRESS CODE</p>
        <p className="text-center mt-0 address__">
          We kindly request that guests wear formal black attire.
        </p>
      </div>

      <hr className="hr hr-blurry" />

      <p className="text-center mt-5 timeline__">WEDDING</p>
      <p className="text-center timeline__">TIMELINE</p>

      <div className="flex justify-center mb-4">
        <img
          src={require("./assets/home/timeline.png")}
          className="pt-3 w-5/6 md:w-3/6"
          alt=""
        ></img>
      </div>

      <hr className="hr hr-blurry" />

      <div className="container my-5 w-75" style={{ maxWidth: "800px" }}>
        <p className="text-center section__heading">
          {" "}
          After two years in the making, we're very excited to tie the knot and
          celebrate with you all in June of 2024! We hope you can make it!
        </p>
      </div>
      <MDBRow className="g-0 m-0">
        <div
          id="intro"
          className="bg-image mb-0 shadow-2-strong home__footer__image"
        ></div>
      </MDBRow>
      <Footer />
    </div>
  );
}

export default Home;
