import "./Footer.css";

export default function Footer() {
  return (
    <footer
      className="text-center text-white fixed-bottom"
      style={{
        backgroundColor: "#fffaf3",
      }}
    >
      <div className="text-center p-3 footer__text inner__footer__background__color">
        © 2023 Copyright: HIPO Adventures
      </div>
    </footer>
  );
}
